import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dropdown-button__options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (_openBlock(), _createBlock(_component_v_dropdown, {
    class: "dropdown-button",
    theme: "dropdown-button"
  }, {
    popper: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        _renderSlot(_ctx.$slots, "options")
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "button")
    ]),
    _: 3
  }))
}