
import { defineComponent } from 'vue';
import { mapState, mapWritableState } from 'pinia';
import { IActionForm } from '../../../components/dashboard/actionForm';
import { IAction } from '../../../entities/action';
import { IActionDefinition } from '../../../entities/actionDefinition';
import {
  getAction, getIntegration, updateAction, deleteAction,
} from '@/services/steamcord/actions';
import { useActionStore } from '@/store/actionStore';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import { formatDate } from '@/util/formatDate';
import ActionForm from '@/components/dashboard/ActionForm.vue';
import DropdownButton from '@/components/buttons/DropdownButton.vue';
import Modal from '@/components/Modal.vue';
import SavePopup from '@/components/dashboard/SavePopup.vue';
import SaveSpacer from '@/components/dashboard/SaveSpacer.vue';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    const actionStore = useActionStore();

    if (!actionStore.integration
        || actionStore.integration.id.toString() !== to.params.integrationID.toString()) {
      const integration = await getIntegration(currentOrg.id, to.params.integrationID.toString());
      if (integration) {
        actionStore.integration = integration;
      }
    }

    if (!actionStore.actionDefinitions) {
      await actionStore.getActionDefinitions();
    }

    const action = await getAction(currentOrg.id, to.params.actionID.toString());

    if (!action) {
      next({ name: 'Not Found' });
      return;
    }

    next((vm) => {
      (vm as any).setAction(action);
    });
  },
  components: {
    ActionForm,
    DropdownButton,
    Modal,
    SavePopup,
    SaveSpacer,
  },
  computed: {
    actionChanged(): boolean {
      return !!this.original && JSON.stringify(this.action) !== this.original;
    },
    definitions(): IActionDefinition[] {
      return this.actionDefinitions as IActionDefinition[];
    },
    formattedDate(): string | undefined {
      if (!this.integration) {
        return undefined;
      }

      return formatDate(this.integration.createdDate as Date);
    },
    ...mapState(useActionStore, ['actionDefinitions', 'integration']),
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, { appLoading: 'loading' }),
  },
  data() {
    return {
      action: undefined as IAction | undefined,
      deleteLoading: false,
      loading: false,
      original: '',
      showDeleteModal: false,
    };
  },
  methods: {
    async deleteAction() {
      if (!this.action || this.deleteLoading || !this.currentOrg || !this.integration) {
        return;
      }

      this.deleteLoading = true;

      await deleteAction(this.currentOrg.id, this.action.id);

      await this.$router.replace(
        `/dashboard/${this.$route.params.subdomain}/integrations/${this.integration.id}`,
      );
    },
    async onSubmit() {
      if (this.loading || !this.currentOrg || !this.action) {
        return;
      }

      if ((this.$refs.actionForm as IActionForm).validate().hasErrors) {
        return;
      }

      this.loading = true;

      await updateAction(this.currentOrg.id, this.action.id, this.action as IAction);
      this.original = JSON.stringify(this.action);

      this.loading = false;
    },
    setAction(action: IAction) {
      this.action = action;
      this.original = JSON.stringify(action);
    },
  },
  async mounted() {
    this.appLoading = false;
  },
});
